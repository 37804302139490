@import './components/Variables';

html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  height: 100vh;
  position: relative;

  .login {
    background: #f3f3f3;
  }
}

.form {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .form-inner {
    align-self: center;
    width: 37rem;

    img {
      width: 10rem;
      height: 10rem;
      object-fit: cover;
      display: block;
      margin: 0 auto 2rem;
  
    }

    form {
      box-shadow: 0 10px 16px 0px rgba(221, 221, 221, 1);
      background: $white;
      border-radius: 5px;
      padding: 2.8rem 2.4rem;    
      width: 32rem;
      display: flex;
      flex-direction: column;

      .input-container { 
        display: flex;
        flex-direction: column;
        margin-bottom: 1.2rem;

        label { 
          font-size: 1.4rem;
          margin-bottom: .5rem;
          color: #444;
        }

        input {
          padding: 1rem;
          font-size: 1.2rem;
          border-radius: 4px;
          background: $white;
          border: 1px solid #C2C2C2;
        }
      }
    }

    .copyright {
      margin-top: 2.8rem;
      p {
        text-align: center;
      }
    }
  }
}

.btn-container {
  display: flex;
  padding-top: .6rem;

  .btn {
    padding: 1.4rem 0;
    width: 100%;
    border: 0;
    border-radius: 5rem;
    color: $white;
    transition: .2s ease;
    cursor: pointer;
    font-weight: 600; 
    
    &:nth-child(2) {
      margin-left: 1.2rem;
    }
  }
  
  .btn-primary {
    background: $blue;
    color: $white;
    &:hover {
      background: darken($blue, 20%)
    }   
  }
  
  .btn-secundary {
    background: transparent;
    border: 2px solid $blue;
    color: $blue;
    &:hover {
      background: transparent;
      color: darken($blue, 20%);
      border: 2px solid darken($blue, 20%)
    }   
  }
}

.copyright {
  p {
    font-size: 1.2rem;
    span {
      &:nth-child(2) {
        margin-left:.4rem;
      }
    }
  }
}