@import '../Variables';

.qr-code {
    display: flex;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;

    .sidebar {
      background: $grey;
      color: $black;
      display: flex;

      .sidebar-inner {
        display:flex;
        align-items: center;

        .logo {
          img {
            position: absolute;
            top: 2rem;
            left: 2rem;
            width: 5rem;
          }
        }

        .logout {
          button {
            position: absolute;
            bottom: 2rem;
            left: 2rem;
            cursor: pointer;
            padding: 0;
            border: 0;
            color: $black;
            &:hover {
              color: darken($black, 20%);
            }
          }
        }

        .control-container {
          margin-top: -3.5rem;
          padding: 0 2.5rem 2.5rem 2.5rem;
          min-width: 280px;

          form {
            width: 100%;

            .href {
              font-size: 1.4rem;
              span {
                &:nth-child(2) {
                  margin-left: .5rem;
                }
              }
            }

            h2 {
              font-size: 3.8rem;
              line-height: 1.2;
              font-weight: bold;
              margin-bottom: .5rem;
              color: $black;
              padding: 0;
              margin: 0.3rem 0 2.4rem;
            }

            h5 {
              font-size: 1.6rem;
              font-weight: normal;
              color: #707070;
              padding: 0;
              margin: 0;
            }

            * {
              margin-bottom: 1rem;
            }

            input {    
              padding: 1.4rem 0 1.4rem 2rem;
              max-width: 260px;
              width: inherit;
              border-radius: 10rem;
              border: 1px solid #D4D4D4;
              color: $black;
              &:placeholder {
                color: #757575;
                font-weight: normal;
              }
            }

            button {
              height: 100%;
            }
          }
        }
      }
    }

    main {
      width: 100%;
      display: flex;
      justify-content: center;
      
      .canvas-container {
        display:flex;
        align-items: center;
      }

      .copyright {
        position: absolute;
        bottom: .75rem;
        right: 2rem;
      }
    }
}
#qrcode  {
    background: url("../../assets/placeholder.png") no-repeat;
    background-size: contain;  
    border: 1px solid $white;
    transition: .2s ease-out;
    border-radius: 4px;
}

#qrcode, #qrcode canvas, #qrcode img {
    height: 512px !important;
    width: 512px !important;
    border: 0 !important;
    border-radius: 4px;
}

.created {
    box-shadow: 0 10px 16px 0px rgba(221, 221, 221, 1);
}